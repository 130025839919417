import React from 'react';
import {
  Added,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="April 2019" subnav="release-notes">
      <div id="April2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          During our first month of focus on React Native, we built everything
          you'd need to start using type on mobile... while also making a few
          updates for web. (We couldn't help ourselves!) Data tables are coming
          into their own and, perhaps most importantly, Words has continued to
          evolve—
          <a className="uni-link uni-link--article" href="/rn-2019-04.png">
            AP style
          </a>
          . Read on for every possible link.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Clarity around building interfaces with{' '}
                <Link href="/guidelines/layering/division/design">
                  division
                </Link>
                .
              </Added>
              <Added>
                Guidelines for writing out{' '}
                <Link href="http://hudl.com/design/writing/best-practices/details">
                  teams
                </Link>{' '}
                and{' '}
                <Link href="http://hudl.com/design/writing/best-practices/details">
                  organizations
                </Link>
                .
              </Added>
              <Added>
                Context for when to use each{' '}
                <Link href="https://www.hudl.com/design/writing/writing-for/components/cta-buttons-links">
                  action word
                </Link>
                .
              </Added>
              <Added>
                <Link href="/patterns/mobile-navigation">
                  Mobile nav labels
                </Link>{' '}
                to go <em>forward</em> and <em>back</em>.
              </Added>
              <Improved>
                <Link href="/patterns/empty-states#Value%20Props">
                  Empty state
                </Link>{' '}
                guidelines to include value propositions.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.28.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.28.2"
            />

            <div className={styles.changesList}>
              <Added>
                Theming for{' '}
                <Link href="/components/data-visualization/data-table/design/">
                  data tables
                </Link>
                .
              </Added>
              <Added>
                <code>qaId</code> for data tables.
              </Added>
              <Improved>
                Data table <code>freezeFirst</code> and number sorting.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="6.2.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v6.2.0"
            />

            <div className={styles.changesList}>
              <Added>
                The entire typography suite:
                <List>
                  <li>
                    <Link href="/components/type/headline/code?reactnative">
                      Headline
                    </Link>
                  </li>
                  <li>
                    <Link href="/components/type/headline/code?reactnative">
                      Subhead
                    </Link>
                  </li>
                  <li>
                    <Link href="/components/type/headline/code?reactnative">
                      Text
                    </Link>{' '}
                  </li>
                  <li>
                    <Link href="/components/type/headline/code?reactnative">
                      Item Title
                    </Link>
                  </li>
                </List>
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
